<template>
  <b-row class="" style="height: 100vh !important">
    <loading-flux :value="loading"></loading-flux>
    <sidebar-login />
    <b-col cols="12" lg="6" class="py-4 bg-white">
      <b-row align-v="center" align-h="center" class="h-75 mx-5 px-5">
        <b-col cols="12" md="9" xl="7" class="col-xxxl-5 px-4 mt-5 pt-4">
          <h1 class="font-weigth-bold text-black mt-4" style="font-size: 40px">
            Iniciar sesión
          </h1>
          <p class="text-secondary mt-4">Bienvenido nuevamente.</p>

          <b-form class="mt-4 pt-2 mb-4" @submit.prevent="formSubmit">
            <b-form-group
              label="Correo electrónico :"
              label-for="loginEmail"
              class="mb-4 text-black"
            >
              <b-form-input
                v-model="form.email"
                id="loginEmail"
                :class="{ 'is-invalid': $v.form.email.$error }"
                type="text"
              ></b-form-input>
              <div v-if="$v.form.email.$error" class="invalid-feedback">
                <span v-if="!$v.form.email.required"
                  >Correo electrónico es requerido.</span
                >
                <span v-if="!$v.form.email.email"
                  >Ingresa un correo electrónico valido.</span
                >
              </div>
            </b-form-group>

            <b-form-group
              label="Contraseña :"
              label-for="loginPassword"
              class="mb-3 text-black"
            >
              <b-form-input
                id="loginPassword"
                :class="{
                  'is-invalid': $v.form.password.$error,
                }"
                type="password"
                v-model="form.password"
              ></b-form-input>
              <div v-if="!$v.form.password.required" class="invalid-feedback">
                Contraseña es requerida.
              </div>
            </b-form-group>

            <b-row align-v="center" align-h="between" class="mb-1">
              <b-col class="hp-flex-none w-auto d-flex align-items-center">
                <b-form-checkbox class="">
                  <small class="mx-1"> Recordar </small>
                </b-form-checkbox>
              </b-col>

              <b-col class="hp-flex-none w-auto">
                <b-link class="text-secondary" to="/forgot-password">
                  ¿Olvidaste tu contraseña?
                </b-link>
              </b-col>
            </b-row>

            <b-button
              type="submit"
              :disabled="loading"
              variant="warning"
              class="w-100"
            >
              <b-spinner v-if="loading" small type="grow" class=""></b-spinner>
              Iniciar sesión
            </b-button>

            <div class="mt-3 pt-1 text-center">
              <p class="font-size-14 mb-2">O iniciar Sesión con:</p>
              <ul class="list-inline">
                <li class="list-inline-item" @click="loginWithGoogle">
                  <b-button class="text-danger" variant="outline-danger">
                    <i class="mdi mdi-google"></i>
                  </b-button>
                </li>
              </ul>
            </div>
          </b-form>
          <b-col cols="12" class="mt-5 pt-5">
            <p class="text-center mb-2 text-secondary">
              Copyright {{ new Date().getFullYear() }} Copec Flux.
            </p>
            <b-row class="mx-3" align-h="center" align-v="center">
              <b-col class="">
                <b-link to="#" class="text-black">
                  <h6>Políticas de privacidad</h6>
                </b-link>
              </b-col>

              <b-col class="">
                <b-link to="#" class="text-black">
                  <h6>Términos y condiciones</h6>
                </b-link>
              </b-col>
            </b-row>
          </b-col>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { validationMixin } from "vuelidate";
const { required, minLength, email } = require("vuelidate/lib/validators");
import { mapActions, mapState } from "vuex";
import SidebarLogin from "./sidebar-login.vue";
import { authMethods, notificationMethods } from "@/state/helpers";

export default {
  components: {
    SidebarLogin,
  },
  data() {
    return {
      loading: false,
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  mounted() {
    this.logout();
  },
  mixins: [validationMixin],
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(4),
      },
      email: {
        required,
        email,
        minLength: minLength(4),
      },
    },
  },
  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    enviroment() {
      return process.env.NODE_ENV;
    },
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    async formSubmit() {
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError && !this.$v.$invalid) {
        this.loading = true;
        this.submitted = true;
        this.tryingToLogIn = true;
        this.authError = null;

        if (!window.navigator.onLine) {
          this.loading = false;
          this.tryingToLogIn = false;
          this.authError =
            "No se ha detectado conexión a internet, intente más tarde";
          this.isAuthError = true;
          return;
        }

        return this.login({
          email: this.form.email,
          password: this.form.password,
        })
          .then((token) => {
            this.tryingToLogIn = false;
            this.isAuthError = false;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.tryingToLogIn = false;
            this.authError =
              "Ha ocurrido un error, verifique el email y la contraseña, si el problema persiste, comuníquese con el administrador";
            this.isAuthError = true;
          });
      }
    },
    loginWithGoogle() {
      this.$auth.authenticate("google").then((response) => {
        this.tryingToLogIn = true;
        this.authError = null;
        this.loginGoogle({
          ...response,
          ambiente:
            process.env.VUE_APP_ENVIROMENT === "development"
              ? "DEV"
              : process.env.VUE_APP_ENVIROMENT === "preproduction"
              ? "PRE"
              : "PROD",
        })
          .then((token) => {
            this.tryingToLogIn = false;
            this.isAuthError = false;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.tryingToLogIn = false;
            this.authError =
              "Ha ocurrido un error, verifique el email y la contraseña, si el problema persiste, comuníquese con el administrador";
            this.isAuthError = true;
          });
      });
    },
  },
};
</script>
